import styled from "styled-components"
import { Theme } from "./Theme"

export const ImgBox = styled.div`
  width: ${props => `${props.elemX}px`};
  height: ${props => `${props.elemY}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  outline: solid 12px white;
  border-radius: 1px;
  position: relative;
`

export const Rollover = styled.div`
  background-color: #252525;
  position: absolute;
  background-color: rgba(0,0,0,0.9);
  top: -12px;
  bottom: -12px;
  left: -12px;
  right: -12px;
  padding:  2rem;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  // clip-path: ${props => `circle(${props.startEffect}% at -50px -50px)`};
  clip-path: ${props => `circle(0 at ${props.startEffect.x}% ${props.startEffect.y}%)`};
  transition: all 0.5s linear;

  &.visible{
    opacity: 1;
    // clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
    clip-path: ${props => `circle(200% at ${props.startEffect.x}% ${props.startEffect.y}%)`};
  }

`
export const RolloverTitle = styled.h4`
  font-style: italic;
  color: ${Theme.colors.red};
  text-transform: uppercase;
  font-weight: 900;
  font-size: 3rem;

  @media (max-width: 1024px){
    font-size: 1.4rem;
  }
`

export const RolloverArgs = styled.ul`

  list-style-type: none;
  padding: 0;
  margin-bottom: 1.6rem;

  li{
    font-style: italic;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;

    @media (max-width: 1024px){
      font-size: 1.1rem;
    }
  }
`

export const RolloverButton = styled.a`
  width: 20rem;
  background-color: ${Theme.colors.red};
  color: #252525;
  text-transform: uppercase;
  padding: 1rem 1.6rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover{
    text-decoration: none;
    color: #252525;
    svg{
      margin-right: 1.4rem;
    }
  }

  svg{
    margin-right: 1rem;
    font-size: 2rem;
    transition: all .15s ease-out;
  }

  @media (max-width: 1024px){
    width: 100%;
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
  }
`