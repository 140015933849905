import React from "react";
import Img from "gatsby-image";
import {
  ImgBox,
  Rollover,
  RolloverTitle,
  RolloverArgs,
  RolloverButton,
} from "@Styles/galleryImage";
import { UID } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";

export default function GalleryImage({
  fluid,
  elemX,
  elemY,
  title,
  args,
  slug,
  hasProjectPage,
}) {
  const rolloverRef = React.useRef();
  const uId = UID();
  const [rolloverVisible, setRolloverVisible] = React.useState(false);

  function mouseEnterImgBox() {
    setRolloverVisible(true);
  }

  function mouseLeaveImgBox() {
    setRolloverVisible(false);
  }

  function setEffectStart() {
    const possibilities = [
      { x: 0, y: 0 },
      { x: 100, y: 0 },
      { x: 100, y: 100 },
      { x: 0, y: 100 },
    ];
    const randNumb = Math.floor(Math.random() * 4);
    return possibilities[randNumb];
  }

  return (
    <ImgBox
      elemX={elemX}
      elemY={elemY}
      onMouseEnter={mouseEnterImgBox}
      onMouseLeave={mouseLeaveImgBox}
    >
      <Img
        fluid={fluid}
        style={{
          width: "100%",
          height: "100%",
        }}
        className={uId}
      />
      <Rollover
        ref={rolloverRef}
        className={rolloverVisible && "visible"}
        startEffect={setEffectStart()}
      >
        <RolloverTitle>
          <i>{title}</i>
        </RolloverTitle>
        <RolloverArgs>
          {args.map((arg, idx) => (
            <li key={idx}>{arg}</li>
          ))}
        </RolloverArgs>
        {hasProjectPage && (
          <RolloverButton href={`/${slug}`}>
            <FontAwesomeIcon icon={faPlayCircle} />
            Voir le projet
          </RolloverButton>
        )}
      </Rollover>
    </ImgBox>
  );
}
